<template>
  <div data-app>
    <!-- 1°first ROW -->
    <div class="row">
      <div class="col-md-12">
        <!-- 1°first Card -->
        <div class="card">
          <div class="card-header bg-secondary">
            <h6 class="my-0 card-title">
              Productos vinculados a {{channel.canal}} | ID: {{channel.id}}
              <v-tooltip top>
                <template v-slot:activator="{ on}">
                  <span v-on="on" class="badge badge-warning badge-pill float-right sdw">
                    <i class="fas fa-box text-dark"></i>
                  </span>
                </template>
                <span>Productos vinculados a {{channel.canal}}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on}">
                  <span
                    v-on="on"
                    class="badge badge-secondary badge-pill float-right sdw pointer mr-3"
                    @click="reinitialize()"
                  >
                    <i class="fas fa-sync text-success"></i>
                  </span>
                </template>
                <span>Actualizar Tabla</span>
              </v-tooltip>
            </h6>
          </div>
          <div class="card-body">
            <v-data-table
              :headers="headers"
              :items="productList"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              :search="search"
              sort-by="id"
              class="elevation-1 datatable"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <div class="kt-input-icon kt-input-icon--left">
                    <input
                      v-model="search"
                      append-icon="search"
                      label="Búsqueda"
                      type="text"
                      class="form-control form-control-md col-md-8"
                      placeholder="Buscar..."
                    />
                    <span class="kt-input-icon__icon">
                      <span>
                        <i class="la la-search"></i>
                      </span>
                    </span>
                  </div>
                  <!--<v-text-field
                    class="text-xs-center"
                    v-model="search"
                    append-icon="search"
                    label="Búsqueda"
                  ></v-text-field>-->

                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on}">
                      <div class="card bluesky-line" style="border:none">
                        <span v-on="on" class="badge badge-secondary float-right sdw">
                          <v-toolbar-title class="mr-2">
                            <i class="fab fa-sketch mr-2"></i>
                            {{channel.canal}}
                          </v-toolbar-title>

                          <!--<i class="fas fa-exchange-alt text-white"></i>-->
                        </span>
                      </div>
                    </template>
                    <span>Mercado en Línea</span>
                  </v-tooltip>

                  <v-dialog v-model="dialog" max-width="600px">
                    <!--<template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                        <i class="fa fa-plus"></i> Canal de Venta
                      </v-btn>
                    </template>-->
                    <!--2°second Card -->
                    <!---->
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-form ref="form" v-model="valid" lazy-validation>
                            <!--2°second ROW-->
                            <v-row>
                              <v-col cols="12" sm="2" md="2" v-if="editedItem.id">
                                <v-text-field
                                  v-model="editedItem.id"
                                  name="id"
                                  label="ID"
                                  readonly="readonly"
                                  prepend-icon="vpn_key"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="10" md="10">
                                <v-text-field
                                  v-model="editedItem.name"
                                  :rules="[v => !!v || 'El nombre del Producto es requerido!']"
                                  label="Nombre"
                                  prepend-icon="edit_location"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  v-model="editedItem.sku"
                                  :rules="[v => !!v || 'El sku del producto es requerido!']"
                                  label="SKU"
                                  prepend-icon="edit_location"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  v-model="editedItem.mkp_id"
                                  :rules="[v => !!v || 'El ID del Canal de Venta es requerido!']"
                                  label="ID Canal de Venta"
                                  prepend-icon="edit_location"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  v-model="editedItem.mkpvar_id"
                                  :rules="[v => !!v || 'El ID VAR del Canal de Venta es requerido!']"
                                  label="ID Var Canal de Venta"
                                  prepend-icon="edit_location"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  v-model="editedItem.stock"
                                  :rules="[v => !!v || 'Una Cantidad es requerida!']"
                                  label="Ingresar una Cantidad"
                                  prepend-icon="edit_location"
                                ></v-text-field>
                              </v-col>
                              <!--<v-col cols="12" sm="6" md="4">
                                <v-select
                                  v-model="editedItem.warehouse_id"
                                  :items="warehouses"
                                  :rules="[v => !!v || 'Seleccionar una Bodega es requerido!']"
                                  item-value="id"
                                  item-text="name"
                                  label="Bodegas"
                                ></v-select>
                              </v-col>-->
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#FF6347" text @click="close">Cancelar</v-btn>
                        <v-btn color="#00FF00" text @click="save">Guardar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <!--<v-tooltip bottom>
                  <template v-slot:activator="{ on}">
                    <router-link :to="`/inventario/locations/${item.id}`">
                      <v-icon v-on="on" small class="mr-2">mdi-eye</v-icon>
                    </router-link>
                  </template>
                  <span>Ver Detalles de Productos Asociados</span>
                </v-tooltip>-->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on}">
                    <v-icon
                      small
                      v-on="on"
                      color="#93a2dd"
                      class="mr-2"
                      @click="editItem(item)"
                    >mdi-pencil</v-icon>
                  </template>
                  <span>Editar Localización</span>
                </v-tooltip>
                <!--<v-tooltip bottom>
                  <template v-slot:activator="{ on}">
                    <v-icon v-on="on" small @click="deleteItem(item)">mdi-delete</v-icon>
                  </template>
                  <span>Eliminar Localización</span>
                </v-tooltip>-->
              </template>
              <template v-slot:item.stock="{ item }">
                <v-chip :color="getColorStock(item.stock)" dark>{{ item.stock }}</v-chip>
              </template>
              <template v-slot:no-data>
                <v-btn color="#87CEFA" :loading="loading" @click="reinitialize()">Cargando ..</v-btn>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                :total-visible="10"
                :length="pageCount"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              ></v-pagination>
            </div>
          </div>
          <div class="alert bluesky-line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card:hover,
.sdw {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
  color: black;
}

.bluesky-line:before {
  height: 3px;
  width: 100%;
  content: "";
  background: linear-gradient(
    270deg,
    #20b2aa 7.1%,
    #008b8b 28.4%,
    #00ced1 42.6%,
    #afeeee 56.8%,
    #5f9ea0 71%,
    #1e90ff 92.9%,
    #4169e1
  );
  background-size: 200% 100%;
  animation: rainbow 4s linear infinite;
  border-radius: 0.25rem 0.25em 0 0;
}
@keyframes rainbow {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: -100% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.pointer {
  cursor: pointer;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  props: ["id"],
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    dialog: false,
    valid: true,
    url: "",
    loading: false,
    boolean: false,
    productList: [],
    channel: {},
    search: "",
    dateMsg:
      new Date().toISOString().substr(0, 10) +
      " " +
      new Date().toISOString().substr(11, 5),
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Nombre", value: "name" },
      { text: "SKU", value: "sku" },
      { text: "MKP ID", value: "mkp_id" },
      { text: "MKP Var ID", value: "mkpvar_id" },
      { text: "Stock", value: "stock" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    warehouses: [],
    salesChannel: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      name: "",
      sku: "",
      mkp_id: 0,
      mkpvar_id: 0,
      stock: 0,
    },
    defaultItem: {
      id: 0,
      name: "",
      sku: "",
      mkp_id: 0,
      mkpvar_id: 0,
      stock: 0,
    },
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "➀ Canal de Venta", route: "/inventario/saleschannel" },
      {
        title: "➁ Detalle Canal de Venta",
        route: `/inventario/saleschannel/saleschannel-detail/${this.id}`,
      },
    ]);
    this.loading = true;
    //this.fetchWarehouses();
    //this.fetchLocations();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Canal de Venta"
        : "Editar Canal de Venta";
    },
  },
  created() {
    //console.log(typeof Number(this.id));

    this.initialize(Number(this.id));
  },
  methods: {
    initialize(channel_id) {
      this.productList = [
        {
          id: 1,
          name: "Producto 1",
          sku: "DSDS2312",
          mkp_id: 21313,
          mkpvar_id: 10,
          stock: 20,
        },
        {
          id: 2,
          name: "Producto 2",
          sku: "DSDS2212",
          mkp_id: 21313,
          mkpvar_id: 10,
          stock: 20,
        },
        {
          id: 3,
          name: "Producto 3",
          sku: "DSDS2d12",
          mkp_id: 21313,
          mkpvar_id: 10,
          stock: 20,
        },
      ];

      this.salesChannel = [
        { id: 1, canal: "Jumpseller" },
        { id: 2, canal: "Magento" },
        { id: 3, canal: "Mercado Libre" },
      ];

      this.fecthSaleChannel(channel_id);
    },

    editItem(item) {
      this.editedIndex = this.productList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      var vm = this;

      if (this.editedIndex > -1) {
        //Object.assign(this.localizacion[this.editedIndex], this.editedItem);
        //let editLocation = Object.assign({}, this.editedItem);
        console.log(vm.$refs.form);
        console.log(vm.editedItem);

        if (!vm.editedItem.name.length || vm.editedItem.name == null) {
          vm.$bvToast.toast("El Codígo de la Localización no debe ir vacío!", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[1].focus();
          return;
        }

        if (!vm.editedItem.sku.length || vm.editedItem.sku == null) {
          vm.$bvToast.toast("El SKU del producto no debe ir vacío", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[2].focus();
          return;
        }

        if (vm.editedItem.mkp_id === 0 || isNaN(vm.editedItem.mkp_id)) {
          vm.$bvToast.toast("Ingresar ID canal de Venta", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[3].focus();
          return;
        }

        if (vm.editedItem.mkpvar_id === 0 || isNaN(vm.editedItem.mkpvar_id)) {
          vm.$bvToast.toast("Ingresar ID Var canal de Venta", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[4].focus();
          return;
        }

        if (vm.editedItem.stock === 0 || isNaN(vm.editedItem.stock)) {
          vm.$bvToast.toast("Ingresar una cantidad porfavor", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[5].focus();
          return;
        }

        if (this.$refs.form.validate()) {
          /*this.axios({
            url: "inventory/locations/" + editLocation.id,
            method: "PUT",
            data: {
              code: editLocation.code,
              default: editLocation.default,
              warehouse_id: editLocation.warehouse_id,
              store_id: editLocation.store_id,
            },
          })
            .then((response) => {
              vm.fetchLocations();

              vm.$bvToast.toast(
                `La ${editLocation.code} ha sido Actualizada el ${vm.dateMsg} exitosamente!`,
                {
                  title: `Información`,
                  variant: "success",
                  solid: true,
                  toaster: "b-toaster-bottom-center",
                }
              );
            })
            .catch((error) => {
              console.log(error);
            });*/
        }
      }
      this.close();
    },
    getColorStock(stock) {
      if (stock >= 15) return "#32CD32";
      else if (stock >= 10) return "#D2691E";
      else return "#B22222";
    },
    fecthSaleChannel(channel_id) {
      //console.log(this.salesChannel);
      //console.log(this.canal);
      var vm = this;

      vm.salesChannel.map(function (row) {
        //console.log(typeof Number(this.id));
        //console.log(typeof row.id);
        if (row.id === channel_id) {
          //console.log(this.id);
          //console.log()
          //console.log(row.id);
          //console.log(row.id);
          vm.channel = { id: row.id, canal: row.canal };
          console.log({ id: row.id, canal: row.canal });

          //this.canal.push({"id": row.id, "name": row.name });

          // this.Channel = row;
        }
      });
    },
    fetchProductList() {
      var vm = this;
      this.loading = false;

      const productList = [
        {
          id: 1,
          name: "Producto 1",
          sku: "DSDS2312",
          mkp_id: 21313,
          mkpvar_id: 10,
          stock: 20,
        },
        {
          id: 2,
          name: "Producto 2",
          sku: "DSDS2212",
          mkp_id: 21313,
          mkpvar_id: 10,
          stock: 20,
        },
        {
          id: 3,
          name: "Producto 3",
          sku: "DSDS2d12",
          mkp_id: 21313,
          mkpvar_id: 10,
          stock: 20,
        },
      ];

      vm.pageCount = Math.ceil(productList.length / 10);
      vm.productList = productList;
      /*this.axios({
        url: "inventory/locations",
        method: "GET",
        data: {
          store_id: vm.store_id,
        },
      })
        .then((response) => {
          //console.log(response.data.data);
          this.loading = false;
          vm.localizacion = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });*/
    },
    reinitialize() {
      this.productList = [];
      this.loading = true;

      this.fetchProductList();
    },
  },
};
</script>